import React from 'react'
import clsx from 'clsx'

import { useStores } from 'config/store'

import { Observer } from 'mobx-react'
import { ReactComponent as Percent } from '../../../assets/img/call-pro/percent_white.svg'
import {
  VolumeUp,
  CallOutlined,
  PersonAddOutlined,
  PhoneMissedOutlined,
  CachedOutlined,
  ThumbUpOutlined,
} from '@material-ui/icons'

// Styles
import { useAllStatsCardStyles } from './AllStats.style'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'
import StatCard from '../../StatCard/StatCard'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

const AllStats = () => {
  const classes = useAllStatsCardStyles()
  const { callsStore, dashboardUIStore, appointmentStore, accountStore } =
    useStores()

  const account: Account = accountStore.accounts[0]

  const handleClick = (outcome: CallOutcome) => {
    if (callsStore.filters.outcome === outcome) {
      return
    }

    dashboardUIStore.setCurrentTabbedOpened(CallDashboardTabs.ALL_CALLS)

    if (outcome === CallOutcome.APPOINMENT_MADE) {
      appointmentStore.resetByTab()
      appointmentStore.setFilters({ outcome })
      appointmentStore.refreshAllAppointmentsTab()
    } else {
      appointmentStore.resetByTab()
      callsStore.resetByTab(CallDashboardTabs.ALL_CALLS)
      callsStore.setFilters({ outcome })
      callsStore.refreshAllCallsTab()
    }
  }

  return (
    <Observer
      render={() => {
        const { callSummaryCount } = callsStore
        const {
          all,
          allAppointmentSetByStaff,
          opportunityByStaff,
          opportunity,
          appointment,
          missed,
          followUp,
        } = callSummaryCount

        const isLoading = callsStore.loadingStates.callsSummaryCount
        const missedRate = Math.round((missed / (missed + opportunity)) * 100)
        const conversionRate = Math.round(
          (allAppointmentSetByStaff / opportunityByStaff) * 100
        )

        return (
          <div className={clsx(classes.container)}>
            <StatCard
              label="All Calls"
              count={all}
              icon={<CallOutlined />}
              isLoading={isLoading}
              tooltipText="This is the total number of calls that came through your
              tracking line. *Some can be test calls, spam calls, etc."
              handleClick={handleClick}
              callOutcome={CallOutcome.ALL_CALLS}
              actionLabel="View Calls"
            />

            <StatCard
              label="Opportunity Calls"
              count={opportunity}
              icon={<ThumbUpOutlined />}
              isLoading={isLoading}
              tooltipText="This is the total number of calls that we scored as valid
              appointment opportunities including missed calls, unanswered
              and voicemails that require follow up."
              handleClick={handleClick}
              callOutcome={CallOutcome.OPPORTUNITY}
              actionLabel="View Calls"
            />
            <StatCard
              label="Appointments"
              count={appointment}
              icon={<PersonAddOutlined />}
              isLoading={isLoading}
              tooltipText="This is the total number of appointments scheduled."
              handleClick={handleClick}
              callOutcome={CallOutcome.APPOINMENT_MADE}
              actionLabel="View Calls"
            />
            <StatCard
              label="Conversion Rate"
              icon={<Percent />}
              isLoading={isLoading}
              tooltipText="This is the average rate at which the staff converts
              answered opportunity calls into scheduled appointments."
              handleClick={handleClick}
              callOutcome={CallOutcome.APPOINTMENT}
              actionLabel="View Calls"
            >
              <div className={clsx(classes.conversionRateCount)}>
                {conversionRate ? conversionRate : 0}%
              </div>
            </StatCard>
            <StatCard
              label="Missed Calls"
              icon={<PhoneMissedOutlined />}
              isLoading={isLoading}
              tooltipText="This is the total number of calls that were missed,
              unanswered, IVR, or voicemail that we believe are possibly
              opportunity calls and need staff follow up."
              handleClick={handleClick}
              callOutcome={CallOutcome.MISSED_CALL}
              actionLabel="View Calls"
            >
              <div className={clsx(classes.missedCallValue)}>
                <div className={clsx(classes.missedCallCount)}>{missed}</div>
                <div className={clsx(classes.missedCallRate)}>
                  {missedRate ? missedRate.toFixed() : 0}%
                </div>
              </div>
            </StatCard>
            <StatCard
              label="Follow-up Opportunities"
              count={followUp}
              icon={<CachedOutlined />}
              isLoading={isLoading}
              tooltipText={formatTextByIndustry({
                account,
                text: 'Total number of potential new {CLIENT_LABEL} opportunities over the last 30 days. Staff follow up is needed to resolve and remove from this list.',
              })}
              handleClick={handleClick}
              callOutcome={CallOutcome.FOLLOWUP}
              actionLabel="View Calls"
            />
          </div>
        )
      }}
    ></Observer>
  )
}

export default AllStats
