import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { grey } from '@material-ui/core/colors'

import Mailing from 'models/Mailing'
import MailingAuditNotice from 'components/Mailings/MailingAuditNotice'
import useStyles from './MailingCardInfo.style'
import { useStores } from 'config/store'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'
import { isEmpty } from 'lodash'

const MailingCardInfo: React.FC<{
  mailing: Mailing
  index: number
}> = ({ mailing }) => {
  const classes = useStyles()

  const { accountStore } = useStores()
  const account: Account = accountStore.accounts[0]

  const matchedLastAuditDate: any = new Date(mailing.matchedLastAudit)
  const currentDate: any = new Date()
  const timeDifference = Math.abs(currentDate - matchedLastAuditDate)
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

  const noAuditCampaignTitle = 'No Audit Campaign'
  const noAuditCampaingDescription = formatTextByIndustry({
    account,
    text: `This campaign has not been audited yet. Send us an updated new {CLIENT_LABEL} list to see the most accurate metrics.`,
  })

  const auditedCampaignTitle = 'Audited Campaign'
  const auditedCampaignDescription = formatTextByIndustry({
    account,
    text: `This campaign was audited for new {CLIENT_LABEL} matchback. Send us an updated new {CLIENT_LABEL} list to see if you've gotten any results since your last audit.`,
  })

  const outdatedAuditCampaignTitle = 'Outdated Audit Campaign'
  const outdatedAuditCampaignDescription = formatTextByIndustry({
    account,
    text: `This campaign was last audited 4 weeks after the first call which means you will be missing upto 30% of the results. Send us an updated new {CLIENT_LABEL} list to see the most accurate metrics.`,
  })

  const { deliveryWeek, campaignType, mediaType, label } = mailing

  const tdw = deliveryWeek
    ? moment(mailing.deliveryWeek).format('MM/DD/YY')
    : '' 
  const titleFmt = (parts: string[]) => parts.filter(Boolean).join(' - ') || '-'

  const headingText = titleFmt([tdw, campaignType as string])
  const subHeadingText  = titleFmt([mediaType as string, label as string])

  return (
    <div className={classes.root}>
      <div>
        <span className={classes.primaryText}>
          {headingText}
        </span>
        <span className={classes.secondaryText}>
          {subHeadingText}
        </span>
      </div>
      <MailingAuditNotice
        title={
          mailing.matchedLastAudit === ''
            ? noAuditCampaignTitle
            : daysDifference <= 31
            ? auditedCampaignTitle
            : outdatedAuditCampaignTitle
        }
        description={
          mailing.matchedLastAudit === ''
            ? noAuditCampaingDescription
            : daysDifference <= 31
            ? auditedCampaignDescription
            : outdatedAuditCampaignDescription
        }
      >
        {mailing.matchedLastAudit === '' ? (
          <InfoOutlinedIcon
            width={22}
            height={22}
            style={{ color: grey[500] }}
          />
        ) : daysDifference <= 31 ? (
          <CheckCircleOutlinedIcon
            width={22}
            height={22}
            style={{ color: grey[500] }}
          />
        ) : (
          <QueryBuilderOutlinedIcon
            width={22}
            height={22}
            style={{ color: grey[500] }}
          />
        )}
      </MailingAuditNotice>
    </div>
  )
}

export default observer(MailingCardInfo)
