// CORE
import React, { ChangeEvent, useRef } from 'react'
import { observer } from 'mobx-react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@material-ui/lab/Autocomplete'

// COMPONENTS
import CustomTable from '../../Table/Table'
import Card from '../../Cards/Card'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import CallDetailsWrapper from 'components/CallPro/SideDrawer/CallDetailsWrapper/CallDetailsWrapper'
import LoadingData from 'components/ProgressIndicator/LoadingData'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ToolTip from '@material-ui/core/Tooltip'

// UTILITY
import useSidePanel from '../../../helpers/hooks/useSidePanel'
import {
  removeSpecialChar,
  removeSpaceAndSpecialChar,
} from '../../../helpers/stringFormatter'
import getCallHandler from 'components/CallPro/lib/getCallHandler'

// STYLES
import { useStyles } from '../AppointmentCalls/AppointmentCalls.style'
import { autoCompleteStyles } from '../OpportunityCalls/OpportunityCalls.style'

// OpportunityCalls/OpportunityCalls.style
// STORES
import { useStores } from 'config/store'

// CONFIGS
import {
  callProCallsColumns,
  renderCallProDashboardColumns,
} from 'components/CallPro/Tables/AppointmentTableColumns'

// INTERFACES
import { CallOutcome, StaffInterface } from 'interfaces/Call.interface'
import classNames from 'classnames'
import { Theme, useMediaQuery } from '@material-ui/core'
import Account from 'models/Account'

const Appointments = observer(() => {
  const classes = useStyles()
  const autoCompleteClass = autoCompleteStyles()
  const { frontDeskStore, appointmentStore, accountStore } = useStores()
  const { drawerOpen, handleCloseDrawer, handleSelectCall } = useSidePanel()
  const delayedSearch = useRef<NodeJS.Timeout | null>(null)
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )
  const account: Account = accountStore.accounts[0]

  const runSearch = (query: string) => () => {
    delayedSearch.current = null
    appointmentStore.setPaginationPage(1)
    appointmentStore.setSearchQuery(query)
    appointmentStore.refreshAppointments()
  }

  const runFilter = (callHandler: StaffInterface | null) => {
    const { callHandlerId, answeredBy } = getCallHandler(callHandler)
    appointmentStore.setPaginationPage(1)
    appointmentStore.setFilters({
      callHandlerId,
      answeredBy,
      outcome: CallOutcome.APPOINMENT_MADE,
    })
    appointmentStore.refreshAppointments()
  }

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = /\d/.test(e.target.value)
      ? removeSpecialChar(e.target.value)
      : removeSpaceAndSpecialChar(e.target.value)

    if (delayedSearch.current !== null) {
      clearTimeout(delayedSearch.current)
      delayedSearch.current = null
    }

    delayedSearch.current = setTimeout(
      runSearch(searchTerm),
      1200
    ) as unknown as NodeJS.Timeout
  }

  const handleAnsweredByQueryChange = (
    event: ChangeEvent<{}>,
    value: StaffInterface | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<StaffInterface> | undefined
  ) => {
    runFilter(value ? value : null)
  }

  const onColumnSort = (key: string) => {
    appointmentStore.toggleSorting(key)
    appointmentStore.refreshAppointments()
  }

  const onChangePage = (page: number) => {
    appointmentStore.setPaginationPage(page)
    appointmentStore.refreshAppointments()
  }

  const onChangePageSize = (pageSize: number) => {
    appointmentStore.setPaginationSize(pageSize)
    appointmentStore.refreshAppointments()
  }

  const {
    loadingStates,
    pagination,
    appointmentsMadePerPage,
    appointmentsMadeTotalCount,
  } = appointmentStore

  return (
    <>
      <Card
        title={
          <ToolTip
            id="appointment"
            title={''}
            placement="bottom-start"
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <div>
              <span>Appointments </span>
              {!mobileDevice ? (
                <InfoOutlinedIcon className={classes.grade_icon} />
              ) : null}
            </div>
          </ToolTip>
        }
        classes={{
          root: classes.cardRoot,
          content: classes.cardContent,
        }}
      >
        <Grid
          container
          spacing={1}
          className={classNames(mobileDevice ? classes.filtersMobile : null)}
        >
          <Grid item xs={12} sm={8}>
            <TextField
              className={classes.searchInput}
              id="input-with-icon-grid"
              placeholder="Search name or number"
              onChange={handleSearchQueryChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              className={classes.searchFilter}
              disabled={frontDeskStore.loadingStates.frontDeskStaff}
              id="combo-box-demo"
              classes={autoCompleteClass}
              size={'medium'}
              options={frontDeskStore.frontDeskStaff}
              onChange={handleAnsweredByQueryChange}
              getOptionLabel={(option) => option.name}
              style={{ fontSize: '9px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter by Answered by"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
        <CustomTable
          columns={callProCallsColumns(account)}
          rows={renderCallProDashboardColumns({
            appointments: appointmentsMadePerPage,
            currentPage: pagination.page,
            pageSize: pagination.pageSize,
            stopAudio: false,
          })}
          defaultRowPerPage={pagination.pageSize}
          isLoading={loadingStates.appointmentsMade}
          orderBy={pagination.sort}
          dir={pagination.dir}
          onColumnSort={onColumnSort}
          onChangePageSize={onChangePageSize}
          onChangePage={onChangePage}
          totalRowsCount={appointmentsMadeTotalCount}
          currentPage={pagination.page}
          handleSelectCall={handleSelectCall}
        />
        <LoadingData hidden={!loadingStates.appointmentsMade} />
      </Card>
      <CallDetailsDrawer
        title="Call Details"
        drawerOpen={drawerOpen}
        handleCloseDrawer={handleCloseDrawer}
      >
        <CallDetailsWrapper />
      </CallDetailsDrawer>
    </>
  )
})

export default Appointments
