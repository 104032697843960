import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import { KPICardContainer, KPIGradeEvaluator, KPIValues } from '../KPICard'
import { LinearProgress } from 'components/CallPro/LinearProgress'

import { useStyles } from './AskedForAdditionalAppointment.styles'
import { formatNumber } from 'helpers/utility'
import { ProTip } from '../ProTip'

import ProTipImage from '../../../../assets/img/call-pro/pro-tips/front-desk-pro-tip-multiple-appts.png'
import GradeToolTipValue from '../GradeToolTipValue'
import SeeIndividualMetric from './SeeIndividualMetric/SeeIndividualMetric'
import { useReactQuery } from './SeeIndividualMetric/_hooks'
import EmptyBarChart from 'components/CallPro/EmptyCharts/EmptyBarChart'

interface AskedForAdditionalAppointmentInterface {
  isAccordionContent?: boolean
}

const AskedForAdditionalAppointment = (
  props: AskedForAdditionalAppointmentInterface
) => {
  const classes = useStyles()
  const { isAccordionContent } = props

  const { isFetching, data } = useReactQuery()
  const summary = data!['summary']

  if (isFetching) {
    return (
      <Skeleton
        variant="rect"
        width={'100%'}
        height={'100%'}
        style={{ borderRadius: 10, minHeight: '29rem' }}
      />
    )
  }

  const total = summary.total ?? 0
  const asked = summary.askedForAdditionalAppointment ?? 0
  const gradeName = summary.askedForAdditionalAppointmentGrade ?? 'A'
  const notAsked = total - asked
  const askedPercentage = summary.askedForAdditionalAppointmentPercentage ?? 0
  const notAskedPercentage = 100 - askedPercentage

  if (!total) {
    return <EmptyBarChart />
  }

  return (
    <KPICardContainer
      isAccordionContent={isAccordionContent}
      title="ASKED FOR ADDITIONAL APPOINTMENTS"
      tooltip="When an appointment is scheduled, this is the rate at which the staff asked the caller to schedule any additional appointments. Excludes appointment calls when caller requests multiple appointments to be scheduled."
    >
      <KPIGradeEvaluator
        isAccordionContent={isAccordionContent}
        value={askedPercentage}
        total={formatNumber(total)}
        totalText="Calls"
        gradeName={gradeName}
      />
      <div className={classes.progressWrapper}>
        <LinearProgress
          hideProgressDetails={true}
          value={asked}
          total={total}
        />
      </div>
      <KPIValues
        isAccordionContent={isAccordionContent}
        data={[
          {
            percentage: askedPercentage,
            label: 'Asked',
            color: '#4498E7',
            details: asked,
          },
          {
            percentage: notAskedPercentage,
            label: 'Did not ask',
            color: '#d4d4d4',
            details: notAsked,
          },
        ]}
      />
      <SeeIndividualMetric />
      <ProTip
        title="Become a pro at getting multiple appointments scheduled on one call"
        content={GradeToolTipValue(
          'Asked For Additional Appointments',
          gradeName
        )}
        image={ProTipImage}
      />
    </KPICardContainer>
  )
}

export default AskedForAdditionalAppointment
