import React from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import { Link } from '@material-ui/core'

import { useStores } from 'config/store'
import { dateFormat } from 'config/callpro.config'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'

import { LinearProgress } from '../LinearProgress'
import { ChartCard } from 'components/CallPro/ChartCard'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'
import CircularProgress from '@material-ui/core/CircularProgress'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useStyles } from './PaymentTypes.styles'

interface PaymentTypesInterface {
  isAccordionContent?: boolean
}

type PaymentType = CallsStore['paymentTypes']['records'][number]['name']

const PaymentTypes = (props: PaymentTypesInterface) => {
  const { isAccordionContent } = props
  const { callsStore, dashboardUIStore } = useStores()
  const classes = useStyles()

  const { paymentTypes: _paymentTypes, loadingStates } = callsStore

  const paymentTypes = _paymentTypes.records
  const firstRecordDate = _paymentTypes.firstRecordDate
  const total = paymentTypes.reduce(
    (accumulator, currentElement) => accumulator + currentElement.value,
    0
  )

  const handleItemClick = (item: PaymentType) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      const paymentType = (item && item.split(' & ')) || []
      callsStore.setFilters({ outcome, paymentType })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <ChartCard
      headerTitle="PAYMENT TYPES"
      tooltipText="This shows what payment types the callers mentioned."
      headerTotalText="Total Calls"
      headerTotalValue={total}
      isAccordionContent={isAccordionContent}
    >
      {loadingStates.paymentTypes ? (
        <div
          style={{
            textAlign: 'center',
            padding: '2rem',
          }}
        >
          <CircularProgress />
        </div>
      ) : total > 0 ? (
        paymentTypes.map((paymentType, key) => {
          return (
            <LinearProgress
              key={`item-${key}`}
              value={paymentType.value}
              total={total}
              label={
                <Link
                  component="button"
                  variant="body1"
                  underline="none"
                  color="initial"
                  onClick={handleItemClick(paymentType.name)}
                  classes={{ root: classes.link }}
                >
                  {paymentType.name}
                </Link>
              }
            />
          )
        })
      ) : (
        <EmptyBarChart />
      )}
      {firstRecordDate.length ? (
        <div className={classes.disclaimer}>
          <InfoOutlinedIcon className={classes.disclaimerIcon} />
          The data used in this graph is derived from the calls we scored since{' '}
          {moment(firstRecordDate).format(dateFormat)}.
        </div>
      ) : null}
    </ChartCard>
  )
}

export default observer(PaymentTypes)
