import React, { useState } from 'react'
import { Observer } from 'mobx-react'
import { runInAction } from 'mobx'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import moment from 'moment-timezone'
import CircularProgress from '@material-ui/core/CircularProgress'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Link } from '@material-ui/core'

import { dateFormat } from '../../../config/callpro.config'
import { useStores } from 'config/store'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'
import { OfferStore } from 'config/store/CallPro/Offer/OfferStore'

import { useOffersCardStyles } from './Offers.style'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import AllOfferDrawer from 'components/CallPro/SideDrawer/AllOffer/AllOfferDrawer'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import { ChartCard } from '../ChartCard'
import { LinearProgress } from '../LinearProgress'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'

interface OffersInterface {
  isAccordionContent?: boolean
}

type OfferItem = OfferStore['offerInquiriesBarChartData'][number]

// MAIN FUNCTION
const Offers = (props: OffersInterface) => {
  const { isAccordionContent } = props
  const classes = useOffersCardStyles()
  const { offerStore, dashboardUIStore, callsStore } = useStores()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleToggleDrawer = () => setDrawerOpen((value) => !value)

  const handleItemClick = (item: OfferItem) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({ outcome, offer: [item.id] })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  // TOOLTIP CONTENT
  const tooltipContent = (
    <div className={classes.metricTooltip}>
      <div className={classes.tooltipTitle}>Click to see the full list</div>
    </div>
  )

  return (
    <Observer
      render={() => {
        const { offerInquiriesBarChartData, offerInquiriesLoading } = offerStore
        const total = offerInquiriesBarChartData.reduce(
          (accumulator, currentElement) => accumulator + currentElement.value,
          0
        )

        return (
          <>
            <ChartCard
              headerTitle="TOP REQUESTED OFFERS"
              tooltipText="The offer or promotion requested or discussed on the call."
              headerTotalText="Total Calls"
              headerTotalValue={total}
              isAccordionContent={isAccordionContent}
            >
              {offerInquiriesLoading ? (
                <div
                  style={{
                    textAlign: 'center',
                    padding: '2rem',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : offerInquiriesBarChartData.length > 0 ? (
                <>
                  {offerInquiriesBarChartData
                    .slice(0, 5)
                    .map((offerInquiry, key) => {
                      return (
                        <LinearProgress
                          key={`item-${key}`}
                          value={offerInquiry.value}
                          total={total}
                          label={
                            <Link
                              component="button"
                              variant="body1"
                              underline="none"
                              color="initial"
                              onClick={handleItemClick(offerInquiry)}
                              classes={{ root: classes.link }}
                            >
                              {offerInquiry.name}
                            </Link>
                          }
                        />
                      )
                    })}
                  <div className={classes.footer}>
                    <div className={classes.container}>
                      <div className={classes.infoContainer}>
                        <div className={classes.info}>
                          <CustomTooltip content={tooltipContent}>
                            <div
                              className={classes.infoDetail}
                              onClick={handleToggleDrawer}
                            >
                              All Offers ({offerInquiriesBarChartData.length})
                            </div>
                          </CustomTooltip>
                        </div>
                      </div>

                      {offerStore.offerStart && (
                        <div className={classes.disclaimer}>
                          <InfoOutlinedIcon
                            className={classes.disclaimerIcon}
                          />
                          The data used in this graph is derived from the calls
                          we scored since{' '}
                          {moment(offerStore.offerStart).format(dateFormat)}.
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <EmptyBarChart />
              )}
            </ChartCard>
            <CallDetailsDrawer
              drawerOpen={drawerOpen}
              handleCloseDrawer={handleToggleDrawer}
              title="All Requested Offers"
            >
              <AllOfferDrawer />
            </CallDetailsDrawer>
          </>
        )
      }}
    />
  )
}
export default Offers
