import React from 'react'
import { observer } from 'mobx-react'
import Skeleton from '@material-ui/lab/Skeleton'

import { KPICardContainer, KPIGradeEvaluator, KPIValues } from '../../KPICard'
import { LinearProgress } from 'components/CallPro/LinearProgress'

import { useStyles } from './StaffAskedToPlaceCallerOnHold.styles'
import { formatNumber } from 'helpers/utility'
import { ProTip } from '../../ProTip'

import ProTipImage from '../../../../../assets/img/call-pro/pro-tips/pro-tip-caller-on-hold.png'
import GradeToolTipValue from '../../GradeToolTipValue'
import SeeIndividualMetric from './SeeIndividualMetric/SeeIndividualMetric'
import { useReactQuery } from './SeeIndividualMetric/_hooks'
import EmptyBarChart from 'components/CallPro/EmptyCharts/EmptyBarChart'

interface StaffAskedToPlaceCallerOnHoldInterface {
  isAccordionContent?: boolean
}

const StaffAskedToPlaceCallerOnHold = (
  props: StaffAskedToPlaceCallerOnHoldInterface
) => {
  const { isAccordionContent } = props
  const classes = useStyles()
  const { isFetching, data } = useReactQuery()
  const summary = data!['summary']

  if (isFetching) {
    return (
      <Skeleton
        variant="rect"
        width={'100%'}
        height={'100%'}
        style={{ borderRadius: 10, minHeight: '29rem' }}
      />
    )
  }

  const total = summary.onHold ?? 0
  const asked = summary.askedOnHold ?? 0
  const gradeName = summary.askedOnHoldGrade ?? 'A'
  const didNotAsked = total - asked
  const askedPercentage = summary.askedOnHoldPercentage ?? 0
  const didNotAskedPercentage = 100 - askedPercentage

  if (!total) {
    return <EmptyBarChart />
  }

  return (
    <KPICardContainer
      isAccordionContent={isAccordionContent}
      title="STAFF PROPERLY REQUESTED TO PLACE CALLER ON HOLD"
      tooltip="This is the call on hold rate at which the staff asks first when they have to place the caller on hold."
    >
      <KPIGradeEvaluator
        isAccordionContent={isAccordionContent}
        value={askedPercentage}
        total={formatNumber(total)}
        totalText="Calls"
        gradeName={gradeName}
      />
      <div className={classes.progressWrapper}>
        <LinearProgress
          hideProgressDetails={true}
          value={asked}
          total={total}
        />
      </div>
      <KPIValues
        isAccordionContent={isAccordionContent}
        data={[
          {
            percentage: askedPercentage,
            label: 'Asked',
            color: '#4498E7',
            details: asked,
          },
          {
            percentage: didNotAskedPercentage,
            label: 'Did not ask',
            color: '#d4d4d4',
            details: didNotAsked,
          },
        ]}
      />
      <SeeIndividualMetric />
      <ProTip
        title="Don't make them wait. Why is keeping the hold time to less than one minute a game-changer"
        content={GradeToolTipValue(
          'Staff Properly Requested To Place Caller On Hold',
          gradeName
        )}
        image={ProTipImage}
      />
    </KPICardContainer>
  )
}

export default observer(StaffAskedToPlaceCallerOnHold)
