import React from 'react'

import { dateFormat } from '../../../../config/callpro.config'
import { KPICardContainer, KPIGradeEvaluator } from '../../FrontDesk/KPICard'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { useStyles } from './PricingRelatedAnswers.styles'
import { useStores } from 'config/store'
import { formatNumber } from 'helpers/utility'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import moment from 'moment'
import { Theme, useMediaQuery } from '@material-ui/core'
import { ProTip } from '../ProTip'

import ProTipImage from '../../../../assets/img/call-pro/pro-tips/pro-tip-pricing.jpg'
import GradeToolTipValue from '../GradeToolTipValue'
import { getGradeName } from '../Utils'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

interface PricingRelatedAnswersInterface {
  isAccordionContent?: boolean
}

const PricingRelatedAnswers = (props: PricingRelatedAnswersInterface) => {
  const classes = useStyles()
  const { isAccordionContent } = props
  const { frontDeskStore, accountStore } = useStores()
  const { pricingRelatedAnswers, pricingRelatedAnswersStart } = frontDeskStore
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )
  const maxBarGraphLength = 10
  const mobileContainerWidth = pricingRelatedAnswers.length * 150
  const containerWidth =
    pricingRelatedAnswers.length > maxBarGraphLength
      ? pricingRelatedAnswers.length * 100
      : '100%'

  const total = pricingRelatedAnswers.reduce(
    (accumulator, currentElement) =>
      accumulator + currentElement.totalCallCount,
    0
  )

  const account: Account = accountStore.accounts[0]

  const scoreWithAppointmentCount = pricingRelatedAnswers.reduce(
    (accumulator, currentElement) =>
      accumulator + currentElement.scoreWithAppointmentCount,
    0
  )

  const percentage = (scoreWithAppointmentCount / total) * 100
  const grading = [
    {
      name: 'A',
      min: 85,
      max: undefined,
    },
    {
      name: 'B',
      min: 70,
      max: 84,
    },
    {
      name: 'C',
      min: 30,
      max: 69,
    },
    {
      name: 'D',
      min: 0,
      max: 29,
    },
  ]

  return (
    <KPICardContainer
      isAccordionContent={isAccordionContent}
      title="PRICING RELATED ANSWERS"
      tooltip="If caller asked pricing related questions, the staff responded with the following answer."
    >
      <KPIGradeEvaluator
        isAccordionContent={isAccordionContent}
        value={percentage}
        total={formatNumber(total)}
        totalText="Calls"
        grading={grading}
      />
      <div
        style={{
          overflowX:
            pricingRelatedAnswers.length > maxBarGraphLength || mobileDevice
              ? 'scroll'
              : 'hidden',
          overflowY: 'hidden',
        }}
      >
        <ResponsiveContainer
          width={mobileDevice ? mobileContainerWidth : containerWidth}
          height={mobileDevice ? 200 : 300}
          className={classes.chartContainer}
        >
          <BarChart data={pricingRelatedAnswers}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="priceAnswer"
              tick={{
                fontSize: pricingRelatedAnswers.length > 5 ? 12 : 14,
              }}
              interval={0}
            />
            <YAxis />
            <Tooltip />
            <Bar
              dataKey="scoreWithAppointmentCount"
              stackId="a"
              fill="#4398E7"
              name="Appointments"
            />
            <Bar
              dataKey="scoreWithoutAppointmentCount"
              stackId="a"
              fill="#E1E1E1"
              name="No Appointment"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      {pricingRelatedAnswersStart && (
        <div>
          <div className={classes.legend}>
            <p>Appointments</p>
            <p>No appointment</p>
          </div>
          <div className={classes.disclaimer}>
            <InfoOutlinedIcon className={classes.disclaimerIcon} />
            The data used in this graph is derived from the calls we scored
            since {moment(pricingRelatedAnswersStart).format(dateFormat)}.
          </div>
        </div>
      )}
      <ProTip
        title={formatTextByIndustry({
          account,
          text: `Overcome {CLIENT_LABEL} objections to insurance, fees, and other issues`,
        })}
        content={GradeToolTipValue(
          'Pricing Related Answers',
          getGradeName(grading, percentage)
        )}
        image={ProTipImage}
      />
    </KPICardContainer>
  )
}

export default PricingRelatedAnswers
