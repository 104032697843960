// CORE
import React from 'react'
import { observer } from 'mobx-react'

// COMPONENTS
import CustomTable from '../../Table/Table'
import Card from '../../Cards/Card'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import CallDetailsWrapper from 'components/CallPro/SideDrawer/CallDetailsWrapper/CallDetailsWrapper'
import LoadingData from 'components/ProgressIndicator/LoadingData'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ToolTip from '@material-ui/core/Tooltip'
import DownloadCallsButton from 'components/CallPro/DownloadCallsButton/DownloadCallsButton'

// UTILITY
import useSidePanel from '../../../helpers/hooks/useSidePanel'

// STYLES
import { useStyles } from './NonOpportunityCalls.style'

// STORE
import { useStores } from 'config/store'

// CONFIGS
import {
  callProCallsColumns,
  renderCallProDashboardColumns,
} from 'components/CallPro/Tables/CallsTableColumns'

// INTERFACES
import { Theme, useMediaQuery } from '@material-ui/core'
import CallTableFilters from 'components/CallPro/CallTableFilters/CallTableFilters'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

const NonOpportunityCalls = observer(() => {
  const classes = useStyles()
  const { callsStore, accountStore } = useStores()
  const { drawerOpen, handleCloseDrawer, handleSelectCall } = useSidePanel()
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const account: Account = accountStore.accounts[0]

  const onColumnSort = (key: string) => {
    callsStore.toggleSorting(key)
    callsStore.refreshCalls()
  }

  const onChangePage = (page: number) => {
    callsStore.setPaginationPage(page)
    callsStore.refreshCalls()
  }

  const onChangePageSize = (pageSize: number) => {
    callsStore.setPaginationSize(pageSize)
    callsStore.refreshCalls()
  }

  const {
    loadingStates,
    pagination,
    nonOpportunityCallsPerPage,
    nonOpportunityCallsTotalCount,
  } = callsStore

  return (
    <>
      <Card
        title={
          <div className={classes.titleWrapper}>
            <ToolTip
              id="non-opportunity-calls"
              title={formatTextByIndustry({
                account,
                text: `All calls not considered a new {CLIENT_LABEL} opportunity may include calls that are Spam, Business, Test, Wrong Number and Personal as well as Medicaid - public aid, wrong practice, specialist etc.`,
              })}
              placement="bottom-start"
              classes={{
                tooltip: classes.tooltip,
              }}
            >
              <div>
                <span>Non-Opportunity Calls </span>
                {!mobileDevice ? (
                  <InfoOutlinedIcon className={classes.grade_icon} />
                ) : null}
              </div>
            </ToolTip>
          </div>
        }
        action={
          <DownloadCallsButton callCount={nonOpportunityCallsTotalCount} />
        }
        classes={{
          root: classes.cardRoot,
          content: classes.cardContent,
        }}
      >
        <CallTableFilters />
        <CustomTable
          columns={callProCallsColumns(account)}
          rows={renderCallProDashboardColumns({
            calls: nonOpportunityCallsPerPage,
            currentPage: pagination.page,
            pageSize: pagination.pageSize,
            stopAudio: false,
          })}
          defaultRowPerPage={pagination.pageSize}
          isLoading={loadingStates.nonOpportunityCall}
          orderBy={pagination.sort}
          dir={pagination.dir}
          onColumnSort={onColumnSort}
          onChangePageSize={onChangePageSize}
          onChangePage={onChangePage}
          totalRowsCount={nonOpportunityCallsTotalCount}
          currentPage={pagination.page}
          handleSelectCall={handleSelectCall}
        />
        <LoadingData hidden={!loadingStates.nonOpportunityCall} />
      </Card>
      <CallDetailsDrawer
        title="Call Details"
        drawerOpen={drawerOpen}
        handleCloseDrawer={handleCloseDrawer}
      >
        <CallDetailsWrapper />
      </CallDetailsDrawer>
    </>
  )
})

export default NonOpportunityCalls
