import React from 'react'

import { KPICardContainer, KPIGradeEvaluator, KPIValues } from '../KPICard'
import { LinearProgress } from 'components/CallPro/LinearProgress'

import { useStyles } from './CallerInteractedWithIVR.styles'
import { useStores } from 'config/store'
import { formatNumber } from 'helpers/utility'

interface AskedForSourceInterface {
  isAccordionContent?: boolean
}

const CallerInteractedWithIVR = (props: AskedForSourceInterface) => {
  const classes = useStyles()
  const { isAccordionContent } = props
  const { frontDeskStore } = useStores()
  const { officeHandlingRate } = frontDeskStore

  const total = officeHandlingRate.callerInteractedWithIVRCount
  const interactedWithIVRPercentage =
    (officeHandlingRate.validCallerInteractedWithIVRCount / total) * 100
  const didNotInteractedWithIVRPercentage =
    ((total - officeHandlingRate.validCallerInteractedWithIVRCount) / total) *
    100

  return (
    <div className={classes.cardWrapper}>
      <KPICardContainer
        isAccordionContent={isAccordionContent}
        title="CALLER INTERACTED WITH IVR"
        tooltip="This is the percentage of callers that interacted or not with the IVR."
      >
        <KPIGradeEvaluator
          isAccordionContent={isAccordionContent}
          value={interactedWithIVRPercentage}
          total={formatNumber(officeHandlingRate.callerInteractedWithIVRCount)}
          totalText="Total Calls"
        />
        <div className={classes.progressWrapper}>
          <LinearProgress
            hideProgressDetails={true}
            value={officeHandlingRate.validCallerInteractedWithIVRCount}
            total={total}
          />
        </div>
        <KPIValues
          isAccordionContent={isAccordionContent}
          data={[
            {
              percentage: interactedWithIVRPercentage,
              label: 'Interacted',
              color: '#4498E7',
            },
            {
              percentage: didNotInteractedWithIVRPercentage,
              label: 'Did not interact',
              color: '#d4d4d4',
            },
          ]}
        />
      </KPICardContainer>
    </div>
  )
}

export default CallerInteractedWithIVR
