import React, { useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import { Link, CircularProgress } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { dateFormat } from 'config/callpro.config'
import { useStores } from 'config/store'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

import { LinearProgress } from '../LinearProgress'
import { ChartCard } from 'components/CallPro/ChartCard'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import { useStyles } from './NoApptScheduledReason.style'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import AllNoApptScheduledReasonDrawer from '../SideDrawer/AllNoApptScheduledReason/AllNoApptScheduledReasonDrawer'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'

interface NoApptScheduledReasonInterface {
  isAccordionContent?: boolean
}

type NoApptScheduledReasonItem =
  CallsStore['noApptScheduledReason']['records'][number]

const NoApptScheduledReason = (props: NoApptScheduledReasonInterface) => {
  const { isAccordionContent } = props
  const { callsStore, dashboardUIStore } = useStores()
  const classes = useStyles()

  const { noApptScheduledReason: _noApptScheduledReason, loadingStates } =
    callsStore
  const noApptScheduledReason = _noApptScheduledReason.records
  const firstRecordDate = _noApptScheduledReason.firstRecordDate

  const total = noApptScheduledReason.reduce(
    (accumulator, currentElement) => accumulator + currentElement.value,
    0
  )

  const [drawerOpen, setDrawerOpen] = useState(false)
  const handleToggleDrawer = () => setDrawerOpen((value) => !value)

  const handleItemClick = (item: NoApptScheduledReasonItem) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({
        outcome,
        noAppointmentWasScheduledReason: [item.name],
      })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  // TOOLTIP CONTENT
  const tooltipContent = (
    <div className={classes.metricTooltip}>
      <div className={classes.tooltipTitle}>Click to see the full list</div>
    </div>
  )

  return (
    <>
      <ChartCard
        headerTitle="TOP REASONS NO APPOINTMENT WAS SCHEDULED"
        tooltipText="For each appointment opportunity call, these are the top reasons why no appointment was scheduled."
        headerTotalText="Total Calls"
        headerTotalValue={total}
        isAccordionContent={isAccordionContent}
      >
        {loadingStates.noApptScheduledReason ? (
          <div
            style={{
              textAlign: 'center',
              padding: '2rem',
            }}
          >
            <CircularProgress />
          </div>
        ) : noApptScheduledReason.length > 0 ? (
          <>
            {noApptScheduledReason.slice(0, 5).map((providedSource, key) => {
              return (
                <LinearProgress
                  key={`item-${key}`}
                  value={providedSource.value}
                  total={total}
                  label={
                    <Link
                      component="button"
                      variant="body1"
                      underline="none"
                      color="initial"
                      onClick={handleItemClick(providedSource)}
                      classes={{ root: classes.link }}
                    >
                      {providedSource.name}
                    </Link>
                  }
                />
              )
            })}
            <div className={classes.footer}>
              <div className={classes.container}>
                <div className={classes.infoContainer}>
                  <div className={classes.info}>
                    <CustomTooltip content={tooltipContent}>
                      <div
                        className={classes.infoDetail}
                        onClick={handleToggleDrawer}
                      >
                        All Reasons No Appointment Was Scheduled (
                        {noApptScheduledReason.length})
                      </div>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              {firstRecordDate.length ? (
                <div className={classes.disclaimer}>
                  <InfoOutlinedIcon className={classes.disclaimerIcon} />
                  The data used in this graph is derived from the calls we
                  scored since {moment(firstRecordDate).format(dateFormat)}.
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <EmptyBarChart />
        )}
      </ChartCard>
      <CallDetailsDrawer
        drawerOpen={drawerOpen}
        handleCloseDrawer={handleToggleDrawer}
        title="All Reasons No Appointment Was Scheduled"
      >
        <AllNoApptScheduledReasonDrawer />
      </CallDetailsDrawer>
    </>
  )
}

export default observer(NoApptScheduledReason)
